import { initializeApp  } from "@firebase/app"
import { getAuth } from 'firebase/auth'
import { getFirestore, collection, doc} from 'firebase/firestore'
import { getStorage} from 'firebase/storage'
import {getFunctions} from 'firebase/functions'

import {getAnalytics, logEvent} from 'firebase/analytics'

let config  = {
  apiKey: "AIzaSyBzmsMi6Nf2y9ymunUvk6vTSJ-kaOlcmO8",
  authDomain: "paolavidenteperu.firebaseapp.com",
  projectId: "paolavidenteperu",
  storageBucket: "paolavidenteperu.appspot.com",
  messagingSenderId: "522207208721",
  appId: "1:522207208721:web:870c6f88536dcc9241d8b0",
  measurementId: "G-C33DC8SGL2"
};

const firebase = initializeApp(config)

const firestore = getFirestore()
const auth = getAuth()
const functions = getFunctions()
const storage = getStorage()
const ColeccionLanding = doc(firestore, 'Landing/Contenido')

const ColeccionLandingImagenes = doc(firestore, 'Landing/Imágenes')
const Colección_blog = collection(firestore, 'Landing/Contenido/Blog')
const Colección_etiquetas = collection(firestore, 'Landing/Contenido/Etiquetas')
const analytics = getAnalytics()
logEvent(analytics)

export {
  functions,
  auth,
  storage,
  firebase,
  ColeccionLanding,
  ColeccionLandingImagenes,
  Colección_blog,
  Colección_etiquetas,
}
