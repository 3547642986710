<template lang="pug">
v-container.pa-0(fluid)
    Navegador
    transition(mode='out-in' 
        enter-active-class='animate__animated animate__fadeInLeft'
        leave-active-class='animate__animated animate__fadeOutLeft')
        router-view
    Boton_whatsapp
    Blog_container(v-if='ruta_actual!="contactame"')
    Contenedor_contacto
    Pie_de_pagina
    //-Cotizacion_container(v-if='ruta_actual!="nosotros"')
    //-iframe#gmap_canvas(v-if='ruta_actual==="inicio"||ruta_actual==="servicios"' width='100%' height='500' 
    //-src='https://maps.google.com/maps?q=Av%20militar%202725&t=&z=13&ie=UTF8&iwloc=&output=embed' 
    //-frameborder='0' scrolling='no' marginheight='0' marginwidth='0')
    //-Pie_de_pagina
</template>
<script>
import { mapState } from 'vuex'
export default {
    computed: {
        ruta_actual(){
            return this.$route.name
        },
        ...mapState({
            Cargando: ({Ayudas}) => Ayudas.cargando,
        }),
    },
    components: {
        Navegador: ()=>import('./componentes/navegador.vue'),
        Boton_whatsapp: ()=>import('./componentes/botón_whatsApp.vue'),
        Pie_de_pagina: ()=>import('./componentes/pie_de_página/pie_de_página.vue'),
        Contenedor_contacto: ()=>import('./componentes/contenedor_contacto.vue'),
        Blog_container: ()=>import('./componentes/novedades_container.vue'),
    }
}
</script>
<style lang="sass" scoped>
.fade-enter, .fade-leave-to
    opacity: 0
    transform: translateX(2em)
.fade-enter-active, .fade-leave-active
    transition: all .3s ease-out
</style>